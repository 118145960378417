<template>
  <b-card no-body>
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center m-2">
        <b-button-group size="sm">
          <b-dropdown
            right
            text="Tambah Toko"
            variant="outline-primary"
            size="sm"
          >
            <b-dropdown-item
              v-for="(item, index) in marketplaces"
              :key="index"
              :item="item"
              class="d-flex align-items-center"
              @click="addIntegration(item.value)"
            >
              <b-img
                :src="require(`@/assets/images/marketplace/${item.value}.png`)"
                alt="user-avatar"
                width="15"
                height="15"
                class="mr-1"
              />
              {{ item.title }}
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>
      <b-table-simple responsive style="zoom: 90%" class="position-relative">
        <b-thead sticky-column>
          <b-tr>
            <b-th class="bg-transparent">
              <b-form-checkbox v-model="selectAll" disabled> </b-form-checkbox>
            </b-th>
            <b-th class="bg-transparent" style="width: 25%">Nama Toko</b-th>
            <b-th class="bg-transparent" style="width: 18%">Sinkron Harga</b-th>
            <b-th class="bg-transparent" style="width: 15%">Sinkron Stok</b-th>
            <b-th class="bg-transparent" style="width: 20%">Status</b-th>
            <b-th class="bg-transparent" style="width: 20%"></b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="tableData.length > 0">
          <b-tr v-for="(item, index) in tableData" :key="index" :item="item">
            <b-td>
              <b-form-checkbox disabled size="md" />
            </b-td>
            <b-td>
              <b-img
                :src="
                  require(`@/assets/images/marketplace/${item.marketplaceName}.png`)
                "
                alt="user-avatar"
                width="30"
                height="30"
                class="mr-1"
              />

              <b-link
                v-if="item.marketplaceName === 'tokopedia'"
                :href="`${item.shopDomain}`"
                target="_blank"
              >
                <span>{{ item.shopName }}</span>
              </b-link>

              <span v-else>
                {{ item.shopName }}
              </span>
            </b-td>
            <b-td>
              <b-form-checkbox
                :checked="item.canSyncPrice"
                size="md"
                switch
                inline
              />
            </b-td>
            <b-td>
              <b-form-checkbox
                :checked="item.canSyncStock"
                size="md"
                switch
                inline
              />
            </b-td>
            <b-td>
              <div>
                <b-badge :variant="item.aprovalStatus === 'approved' ? 'success' : 'primary'">
                  {{
                    item.aprovalStatus === 'approved' ? 'Verified' : item.aprovalStatus.charAt(0).toUpperCase() +
                    item.aprovalStatus.slice(1)
                  }}
                </b-badge>
                <b-button
                v-if="$can('read', 'administrator')"
                  variant="link"
                  size="sm"
                  @click="changeUserStatus(item.shopId, item.shopName)"
                >
                  <Feather-icon icon="EditIcon" />
                </b-button>
              </div>
            </b-td>
            <b-td class="text-right">
              <!-- tombol hapus -->
              <b-button
                variant="outline-danger"
                size="sm"
                @click="deleteIntegration(item.id, item.shopName)"
              >
                <feather-icon icon="TrashIcon" size="16" /> Hapus Toko
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
        <empty-table-row v-else :colspan="6" />
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BThead,
  BTh,
  BFormCheckbox,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BButtonGroup,
  BImg,
  BBadge,
  BLink,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import EmptyTableRow from '@/components/EmptyTableRow.vue';
import TableHeader from '@/components/TableHeader.vue';
import axios from '@axios';

export default defineComponent({
  name: 'IntegrationList',
  components: {
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BThead,
    BTh,
    BFormCheckbox,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BButtonGroup,
    EmptyTableRow,
    TableHeader,
    BImg,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      isFetching: false,
      selectedDatas: [],
      selectAll: false,
      search: '',
      marketplaces: [
        {
          title: 'Tokopedia',
          value: 'tokopedia',
        },
        {
          title: 'Shopee',
          value: 'shopee',
        },
        {
          title: 'Lazada',
          value: 'lazada',
        },
      ],
    };
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    addIntegration(marketplace) {
      if (marketplace === 'tokopedia') {
        this.$swal
          .fire({
            title: 'Tambah Toko',
            input: 'text',
            inputLabel:
              'Pastikan Toko anda sudah Power Merchant / Pro / Official Store',
            inputPlaceholder: 'https://tokopedia.com/nama-toko',
            inputAutoTrim: true,
            inputAttributes: {
              autocapitalize: 'off',
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Tambah',
            inputValidator: value => {
              if (!value) {
                return 'Harap masukkan nama toko anda dengan benar!';
              }

              // Validasi dengan regex
              const urlRegex =
                /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
              if (!urlRegex.test(value)) {
                return 'Harap masukkan URL yang valid!';
              }

              return new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                }, 2000);
              });
            },
            preConfirm: async value => {
              if (value) {
                const response = await axios.post('marketplace/tokopedia', {
                  shopName: value,
                });

                if (response && response.data && response.data.data) {
                  this.$swal.fire({
                    title: 'Berhasil',
                    text: 'Toko berhasil ditambahkan! Silahkan tunggu proses verifikasi dari kami',
                    icon: 'success',
                  });
                }
              }
            },
          })
          .then(result => {
            if (result.isConfirmed) {
              this.searchData();
            }
          });
      } else {
        this.$swal
          .fire({
            title: 'Tambah Toko',
            text: `Anda akan diarahkan ke halaman ${marketplace} untuk login`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#2196f3',
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batal',
          })
          .then(async result => {
            if (result.isConfirmed) {
              const response = await axios.get(
                `${marketplace}/auth/login?ns=false`,
              );

              if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.url
              ) {
                window.location.href = response.data.data.url;
              }
            }
          });
      }
    },
    deleteIntegration(id, shopName) {
      this.$swal
        .fire({
          title: 'Hapus Toko',
          text: 'Apakah anda yakin ingin menghapus toko ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, Hapus',
          cancelButtonText: 'Batal',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal
              .fire({
                title: 'Hapus Toko',
                input: 'text',
                inputLabel: `Ketik "${shopName.toLowerCase()}" untuk mengkonfirmasi`,
                inputPlaceholder: 'Nama Toko',
                inputAutoTrim: true,
                inputAttributes: {
                  autocapitalize: 'off',
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Hapus',
                inputValidator: value => {
                  if (!value) {
                    return 'Harap masukkan nama toko anda dengan benar!';
                  }

                  if (value.toLowerCase() !== shopName.toLowerCase()) {
                    return 'Nama toko yang anda masukkan salah!';
                  }

                  return new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                    }, 2000);
                  });
                },
                preConfirm: async value => {
                  if (value) {
                    const response = await axios.delete(`marketplace/${id}`);
                    if (response && response.data && response.data.data) {
                      this.$swal.fire({
                        title: 'Berhasil',
                        text: 'Toko berhasil dihapus!',
                        icon: 'success',
                      });
                    }
                  }
                },
              })
              .then(res => {
                if (res.isConfirmed) {
                  // slicing data
                  this.tableData = this.tableData.filter(
                    item => item.id !== id,
                  );
                }
              });
          }
        });
    },
    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },
    async searchData(search = null) {
      this.search = search || this.search;
      this.tableData = [];
      this.currentPage = 1;
      this.totalRows = 0;
      await this.fetchData();
    },
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      const params = {
        page: this.currentPage,
        perPage: this.perPage,
      };

      if (this.search) {
        Object.assign(params, this.search);

        // if one key has array and has object like title and value, get the value and join it with comma
        Object.keys(params).forEach(key => {
          if (Array.isArray(params[key])) {
            params[key] = params[key].map(item => item.value).join(',');
          }
        });
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`marketplace?${url}`);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        this.tableData = [...this.tableData, ...response.data.data];
      }

      this.totalRows = response.data.total;
      this.currentPage += 1;
    },
    async changeUserStatus(shopId = null, shopDomain = null) {
      // make sweetalert to choose status and submit
      const { value: status } = await this.$swal.fire({
        title: 'Ubah Status Toko',
        input: 'select',
        inputOptions: {
          pending: 'Pending',
          approved: 'Approved',
          rejected: 'Rejected',
        },
        inputPlaceholder: 'Pilih status',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
        // eslint-disable-next-line consistent-return
        inputValidator: value => {
          if (!value) {
            return 'Harap pilih status toko!';
          }
        },
      });
      const response = await axios.post('marketplace/tokopedia/confirm', {
        shopId,
        shopDomain,
        aprovalStatus: status,
      });

      if (
        response &&
        response.data &&
        response.data.data
      ) {
        this.$swal.fire({
          title: 'Berhasil',
          text: 'Status toko berhasil diubah!',
          icon: 'success',
        });
      }
    },
  },
});
</script>

<style lang="scss">
.swal2-input {
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
